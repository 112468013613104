/**
 * 监听DOM尺寸变化
 *  */    
export default {
    created(el, binding) {
         // 创建一个 ResizeObserver 实例
         const observer = new ResizeObserver(entries => {
            // 当元素大小变化时，触发回调函数
            binding.value(entries[0].contentRect);
          });
          // 监听元素
          observer.observe(el);
      
          // 在组件销毁时取消观察
          el._resizeObserver = observer;
    },
    unmounted(el) {
          // 取消观察
          el._resizeObserver.disconnect();
          delete el._resizeObserver;
    }
  }