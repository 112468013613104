<template>
  <div class="cellBg" :style="cellStyle">
    <span v-if="showPoint" class="point" :style="pointStyle"></span>
    <span :style="textStyle">{{ text }}</span>
  </div>
</template>
<script setup>
import { computed, defineProps, onMounted } from "vue";
import { Level, OffOn, Status, actionStatus } from "./data/index.js";

const props = defineProps([
  "showPoint", // 是否展示实心圆图标
  "text", // 文本
  "textColor", // 文本颜色
  "bgColor", // cell背景色
  "pointStyle", // 实心圆颜色默认与文字颜色相同
  "presetType", // 预置类型，如果传入预支类型名称，会在defaultStyle中匹配内置样式
]);

// 文字，prop传入文字优先级最高，其次是内置类型文字，
// 预支类型文字可能存在同名但是样式不同的清空，通过“_后缀”来区分
// 如果没有传入text并且内置样式也没有匹配搭配，会显示“-”
const text = computed({
  get: () => {
    // 因为会存在同名但是样式不同的情况，通过在名称后面拼接后缀实现区分
    return props.text
      ? props.text
      : props.presetType
        ? props.presetType.toString().split("_")[0]
        : "-";
  },
});

// 默认支持的样式
const defaultStyle = ref({
  普通版: Level.l1,
  高级版: Level.l2,
  旗舰版: Level.l3,
  运行中: Status.s1,
  未激活: Status.s2,
  已激活: Status.s1,
  未删除: Status.s2,
  已删除: Status.s1,
  正常运行: Status.s1,
  异常暂停: Status.s2,
  手动暂停: Status.s4,
  弃用: Status.s5,
  未录入: Status.s2,
  已录入: Status.s1,
  离线: actionStatus.a5,
  在线: actionStatus.a1,
  管理员: actionStatus.a1,
  非管理员: actionStatus.a2,
  启用: actionStatus.a1,
  禁用: actionStatus.a2,
  正常: actionStatus.a1,
  停用: actionStatus.a2,
  失败: actionStatus.a2,
  开: OffOn.on,
  关: OffOn.off,
  是: OffOn.on,
  否: OffOn.off,
  有: OffOn.on,
  无: OffOn.off,
  隐藏: actionStatus.a5,
  显示: actionStatus.a1,
  归还: actionStatus.a4,
  取货: actionStatus.a1,
  公告: Status.s3,
  通知: Status.s2,
  删除: actionStatus.a2,
  新增: actionStatus.a1,
  修改: actionStatus.a2,
  授权: actionStatus.a1,
  导入: actionStatus.a1,
  导出: actionStatus.a1,
  强退: actionStatus.a2,
  生成代码: actionStatus.a3,
  其他: actionStatus.a3,
  清空数据: actionStatus.a2,
  正常_dept: Status.s1, // 通过名称加后缀区分同名样式
  停用_dept: Status.s2,
  成功: actionStatus.a1,
  初次入柜_operatorRecord: actionStatus.a3,
  作废_operatorRecord: actionStatus.a2,
  领取_operatorRecord: actionStatus.a1,
  归还_operatorRecord: actionStatus.a4,
  升级失败: actionStatus.a2,
  升级成功: actionStatus.a1,
  未升级: actionStatus.a5,
  作废_deviceBindProduct: Status.s2,
  空闲_deviceBindProduct: Status.s5,
  使用中_deviceBindProduct: Status.s1,
  未配备: actionStatus.a2,
  配备: actionStatus.a1,
  在职: actionStatus.a1,
  离职: actionStatus.a2,
  普通品配送员: actionStatus.a1,
  危险品配送员: actionStatus.a2,
  已入库_待派送: actionStatus.a3,
  派送中: actionStatus.a4,
  已签收: actionStatus.a1,
  问题单: actionStatus.a2,
  作废单: actionStatus.a5,
  有效: actionStatus.a1,
  无效: actionStatus.a2,
  未签收: actionStatus.a2,
  未开始: actionStatus.a3,
  会议中: actionStatus.a2,
  已结束: actionStatus.a5,
  已生成: actionStatus.a1,
  未生成: actionStatus.a2,
  已启用: actionStatus.a1,
  未启用: actionStatus.a2,
  药箱中: Status.s1, // 通过名称加后缀区分同名样式
  已离箱: Status.s2,
  正常: Status.s1,
  过期: Status.s2,
  临期: Status.s4,

  // 失败: actionStatus.a2
});

// 文字样式
const textStyle = computed({
  get: () => {
    return {
      fontSize: "12px",
      color: defaultStyle.value[props.presetType]
        ? defaultStyle.value[props.presetType].textColor
        : props.textColor,
    };
  },
});

// 是否展示文字前面的实心圆
const showPoint = computed({
  get: () => {
    return defaultStyle.value[props.presetType]
      ? defaultStyle.value[props.presetType].showPoint
      : false;
  },
});

// cell样式
const cellStyle = computed({
  get: () => {
    return {
      padding: "3px 7px",
      height: "20px",
      borderRadius: defaultStyle.value[props.presetType]
        ? defaultStyle.value[props.presetType].borderRadius || "10px"
        : props.borderRadius,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      background: defaultStyle.value[props.presetType]
        ? defaultStyle.value[props.presetType].bgColor
        : props.bgColor,
    };
  },
});

// 实心圆样式
const pointStyle = computed({
  get: () => {
    return {
      height: "8px",
      width: "8px",
      borderRadius: "50%",
      marginRight: "5px",
      background: defaultStyle.value[props.presetType]
        ? defaultStyle.value[props.presetType].pointColor ||
        defaultStyle.value[props.presetType].textColor
        : props.pointColor || props.textColor,
    };
  },
});
</script>
