<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="tagsViewStore.cachedViews">
          <div style="height: 100%;" :key="route.path">
            <component v-if="!route.meta.link" :is="Component" />
          </div>
        </keep-alive>
      </transition>
    </router-view>
    <!-- <iframe-toggle /> -->
  </section>
</template>

<script setup>
import iframeToggle from "./IframeToggle/index"
import useTagsViewStore from '@/store/modules/tagsView'

const tagsViewStore = useTagsViewStore()
</script>

<style lang="scss" scoped>
.app-main {
  /* 56= navbar  56  */
  height: calc(100vh - 56px);
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: #f3f4f8;
  //padding: 16px;

  .home,
  .app-container,
  el-container {
    height: 100%;
    background: #FFFFFF;
    //border-radius: 8px;
    overflow: hidden;
  }
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    /* 84 = navbar + tags-view = 56 + 48 */
    height: calc(100vh - 104px);
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }
}
</style>
