import axios from "axios";

// 判断是否有新版本
const isNewVersion = () => {
  // 生成请求链接，防止浏览器缓存
  let url = `//${window.location.host}/version.json?t=${new Date().getTime()}`;
  // 发送请求
  axios.get(url).then((res) => {
    // 如果响应状态码为200
    if (res.status === 200) {
      // 获取返回的版本号，如果没有则默认为1.0.0
      let vueVersion = res.data.version || "1.0.0";
      // 从localStorage中获取已保存的版本号
      let localVueVersion = localStorage.getItem("vueVersion");
      // 将新版本号保存到localStorage
      localStorage.setItem("vueVersion", vueVersion);
      // 如果localStorage中有版本号且与新版本号不一致，则弹窗提示并刷新页面
      if (localVueVersion && localVueVersion != vueVersion) {
        // alert("检测到新版本，请点击确认刷新页面");
        window.location.reload(true);
        return false;
      } else {
        return true;
      }
    }
  });
};

// 导出函数
export default {
  isNewVersion,
};
