import request from '@/utils/request'

export function getbase(data) {
    return request({
        url: '/system/deviceInfo/list',
        method: 'get',
        params: data
    })
}

export function getoperation(data) {
    return request({
        url: '/nbDevice/sendMsg',
        method: 'post',
        data: data
    })
}

export function sendIotMsg(data) {
    return request({
        url: '/system/deviceInfo/sendMsgToDeviceNew',
        method: 'post',
        data: data
    })
}

export function getdeviceInfo(data) {
    return request({
        url: '/system/deviceInfo/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function getdeviceInfoup(data) {
    return request({
        url: '/system/deviceInfo/saveOrUpdate',
        method: 'put',
        data: data
    })
}

export function deleteFn(data) {
    return request({
        url: `/system/deviceInfo/${data}`,
        method: 'delete',
    })
}

export function getmodule() {
    return request({
        url: '/system/dict/data/list?dictType=sys_module&pageSize=50',
        method: 'get'
    })
}

export function getprotocol() {
    return request({
        url: '/system/dict/data/list?dictType=sys_protocol&pageSize=50',
        method: 'get'
    })
}

export function devicetype() {
    return request({
        url: '/system/dict/data/list?dictType=device_type&pageSize=9999&pageNum=1',
        method: 'get'
    })
}

export function iottype() {
    return request({
        url: '/system/dict/data/list?dictType=iot_command_type&pageSize=50',
        method: 'get'
    })
}

export function deviceStatus() {
    return request({
        url: '/system/dict/data/list?dictType=sys_device_status&pageSize=50',
        method: 'get'
    })
}

export function treeselect() {
    return request({
        url: '/system/user/deptTree',
        method: 'get'
    })
}

export function deviceCodeList(id) {
    return request({
        url: '/system/deviceInfo/deviceCodeList',
        method: 'post',
        data: {
            pageNum: 1,
            pageSize: 10,
            deptId: id,
        }
    })
}

export function Dictadd(data) {
    return request({
        url: '/system/deviceConnectInfo/saveConnection',
        method: 'post',
        data: data
    })
}


export function getmodel(data) {
    return request({
        url: '/system/dict/data/list',
        method: 'get',
        params: {dictType: "device_model"}
    })
}


export function getinfos(data) {
    return request({
        url: '/system/user/getInfo',
        method: 'get'
    })
}

export function productlist(data) {
    return request({
        url: '/system/dict/data/list?pageNum=1&pageSize=100&dictType=sys_product_list',
        method: 'get'
    })
}

export function infolist(data) {
    return request({
        url: '/system/violationRecord/pullViolationInfo',
        method: 'post',
        data: JSON.stringify(data)
    })
}

export function resourceinfo(data) {
    return request({
        url: '/system/violationRecord/getDeviceMonitorInfo',
        method: 'get',
        params: data
    })
}

// 获取IOT状态
export function getIOTState(data) {
    return request({
        url: `/system/systemRemote/getIOTDeviceStatus/` + data,
        method: 'GET',
    })
}
