<template>
  <div style="padding: 0 15px;" @click="toggleClick">

    <svg :class="{ 'is-active': isActive }" class="hamburger" width="20" height="18" viewBox="0 0 20 18" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 1.25C0 0.559644 0.559644 0 1.25 0H18.75C19.4404 0 20 0.559644 20 1.25C20 1.94036 19.4404 2.5 18.75 2.5H1.25C0.559644 2.5 0 1.94036 0 1.25ZM1.707 13.2636L4.681 10.2896C4.88508 10.0849 4.99968 9.80768 4.99968 9.51863C4.99968 9.22958 4.88508 8.95233 4.681 8.74763L1.707 5.77363C1.56709 5.63376 1.38883 5.53854 1.19479 5.5C1.00074 5.46146 0.799625 5.48135 0.616884 5.55714C0.434143 5.63294 0.27799 5.76123 0.168182 5.9258C0.0583739 6.09036 -0.000155032 6.2838 0 6.48163V12.5556C-0.000155032 12.7535 0.0583739 12.9469 0.168182 13.1115C0.27799 13.276 0.434143 13.4043 0.616884 13.4801C0.799625 13.5559 1.00074 13.5758 1.19479 13.5373C1.38883 13.4987 1.56709 13.4035 1.707 13.2636ZM9.25 5.5C8.55964 5.5 8 6.05964 8 6.75C8 7.44036 8.55964 8 9.25 8H18.75C19.4404 8 20 7.44036 20 6.75C20 6.05964 19.4404 5.5 18.75 5.5H9.25ZM8 11.75C8 11.0596 8.55964 10.5 9.25 10.5H18.75C19.4404 10.5 20 11.0596 20 11.75C20 12.4404 19.4404 13 18.75 13H9.25C8.55964 13 8 12.4404 8 11.75ZM1.25 15.5C0.559644 15.5 0 16.0596 0 16.75C0 17.4404 0.559644 18 1.25 18H18.75C19.4404 18 20 17.4404 20 16.75C20 16.0596 19.4404 15.5 18.75 15.5H1.25Z"
        fill="#D1D1D6" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 1.25C0 0.559644 0.559644 0 1.25 0H18.75C19.4404 0 20 0.559644 20 1.25C20 1.94036 19.4404 2.5 18.75 2.5H1.25C0.559644 2.5 0 1.94036 0 1.25ZM1.707 13.2636L4.681 10.2896C4.88508 10.0849 4.99968 9.80768 4.99968 9.51863C4.99968 9.22958 4.88508 8.95233 4.681 8.74763L1.707 5.77363C1.56709 5.63376 1.38883 5.53854 1.19479 5.5C1.00074 5.46146 0.799625 5.48135 0.616884 5.55714C0.434143 5.63294 0.27799 5.76123 0.168182 5.9258C0.0583739 6.09036 -0.000155032 6.2838 0 6.48163V12.5556C-0.000155032 12.7535 0.0583739 12.9469 0.168182 13.1115C0.27799 13.276 0.434143 13.4043 0.616884 13.4801C0.799625 13.5559 1.00074 13.5758 1.19479 13.5373C1.38883 13.4987 1.56709 13.4035 1.707 13.2636ZM9.25 5.5C8.55964 5.5 8 6.05964 8 6.75C8 7.44036 8.55964 8 9.25 8H18.75C19.4404 8 20 7.44036 20 6.75C20 6.05964 19.4404 5.5 18.75 5.5H9.25ZM8 11.75C8 11.0596 8.55964 10.5 9.25 10.5H18.75C19.4404 10.5 20 11.0596 20 11.75C20 12.4404 19.4404 13 18.75 13H9.25C8.55964 13 8 12.4404 8 11.75ZM1.25 15.5C0.559644 15.5 0 16.0596 0 16.75C0 17.4404 0.559644 18 1.25 18H18.75C19.4404 18 20 17.4404 20 16.75C20 16.0596 19.4404 15.5 18.75 15.5H1.25Z"
        fill="#6E7686" />
    </svg>

  </div>
</template>

<script setup>
defineProps({
  isActive: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits()
const toggleClick = () => {
  emit('toggleClick');
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
