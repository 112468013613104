import hasRole from './permission/hasRole'
import hasPermi from './permission/hasPermi'
import copyText from './common/copyText'
import resize from './common/resize'
import focus from './common/focus'
import clickOutSide from "./common/clickOutSide";

export default function directive(app){
  app.directive('hasRole', hasRole)
  app.directive('hasPermi', hasPermi)
  app.directive('copyText', copyText)
  app.directive('resize', resize)
  app.directive('focus', focus)
  app.directive('clickOutSide', clickOutSide)
}