<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="appStore.sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!settingsStore.topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="settingsStore.topNav" />
    <div class="right-menu">
      <!-- <template v-if="appStore.device !== 'mobile'">
        <header-search id="header-search" class="right-menu-item" />
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
      </template> -->
      <div class="avatar-container">
        <!-- 二维码 -->
        <el-popover :popper-class="'popperRadio'" placement="bottom-end" :width="250" :trigger="'hover'">
          <div class="qrCodeBox">
            <div class="webSite">
              <svg-icon style="font-size: 80px" icon-class="guanwang"></svg-icon>
              <!-- <img src="@/assets/icon/svg/guanwang.svg" alt="" /> -->
              <el-link href="http://finder-cas.com/#/home" :underline="false" target="_blank">企业官网</el-link>
            </div>
            <div class="account">
              <svg-icon style="font-size: 80px" icon-class="gongzhonghao"></svg-icon>
              <!-- <img src="@/assets/img/gongzhonghao.png" alt="" /> -->
              <span>公众号</span>
            </div>
          </div>
          <template #reference>
            <div class="qrCode">
              <svg-icon class-name="search-icon" icon-class="qrCode"></svg-icon>
            </div>
          </template>
        </el-popover>
        <!-- 消息管理 -->
        <el-popover :popper-class="'popperRadio'" placement="bottom-end" :width="400" :trigger="'hover'"
          @show="getAllTotal">
          <div class="messageCard">
            <div class="topLine">
              <el-button type="text" @click="handleChangeNoticeType(1)"
                :class="noticeType === 1 ? 'active' : ''">通知(<span>{{ noticeTotal }}</span>)</el-button>
              <el-button type="text" @click="handleChangeNoticeType(2)"
                :class="noticeType === 2 ? 'active' : ''">公告(<span>{{ announcementTotal }}</span>)</el-button>
            </div>
            <div class="middleLine">
              <el-scrollbar>
                <div class="middleItem" v-for="item in noticeList" :key="item.noticeId">
                  <svg-icon class-name="search-icon" :icon-class="item.noticeType === '1' ? 'notice' : 'announcement'
                    "></svg-icon>
                  <div class="message">
                    <div class="title">{{ item.noticeTitle }}</div>
                    <div class="time">{{ item.createTime }}</div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <div class="bottomLine">
              <el-button type="text">全部已读</el-button>
              <span></span>
              <el-button type="text" @click="handleLookMore">查看更多</el-button>
            </div>
          </div>
          <template #reference>
            <div class="qrCode">
              <svg-icon class-name="search-icon" :icon-class="noticeTotal === 0 && announcementTotal === 0
                ? 'noMessage'
                : 'haveMessage'
                "></svg-icon>
            </div>
          </template>
        </el-popover>
        <span class="line"></span>
        <!-- 用户头像 -->
        <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
          <!-- <el-popover placement="bottom" popper-class="userPopover" :width="230" trigger="click">
            <template #reference>
              <div class="avatar-wrapper">
                <img :src="userStore.avatar" class="user-avatar" />
                <el-icon><caret-bottom /></el-icon>
              </div>
            </template>
            <div class="userCard">
              <div class="top">
                <img :src="userStore.avatar" class="user-avatar" />
                <span class="userName">jfeijfiejfe</span>
                <span class="email">78758943754398@784.com</span>
              </div>
              <div class="middle">
                <el-button class="personalMessage">个人资料</el-button>
                <el-button class="personalMessage">修改密码</el-button>
              </div>
              <div class="bottom">
                <el-botton class="logout">推出登录</el-botton>
              </div>


            </div>
          </el-popover> -->
          <el-tooltip class="item" v-model="showUserCard" append-to-body="false" effect="light" placement="bottom">
            <div class="avatar-wrapper">
              <img :src="userStore.avatar" class="user-avatar" />
              <!-- <el-icon><caret-bottom /></el-icon> -->
            </div>
            <template #content>
              <div class="userCardWrapper"></div>
              <div class="userCard">
                <div class="top">
                  <img :src="userStore.avatar" class="user-avatar" />
                  <span class="userName">{{ userStore.nickName }}</span>
                  <span class="email">{{ userStore.email }}</span>
                </div>
                <div class="middle">
                  <el-button class="personalMessage" @click="() => $router.push('/user/profile?activeTab=userinfo')
                    ">个人资料</el-button>
                  <el-button class="personalMessage"
                    @click="$router.push('/user/profile?activeTab=resetPwd')">修改密码</el-button>
                </div>
                <div class="bottom">
                  <el-button class="logout" type="text" @click="logout">退出登录</el-button>
                </div>
              </div>
            </template>
          </el-tooltip>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="userCard">
                <div class="avatar-wrapper">
                  <img :src="userStore.avatar" class="user-avatar" />
                </div>
              </div>

              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item command="setLayout">
                <span>布局设置</span>
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessageBox } from "element-plus";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import HeaderSearch from "@/components/HeaderSearch";
import RuoYiGit from "@/components/RuoYi/Git";
import RuoYiDoc from "@/components/RuoYi/Doc";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import useSettingsStore from "@/store/modules/settings";
import { listNotice } from "@/api/system/notice";
import { onBeforeMount, watch } from "vue";
import router from "@/router";

const appStore = useAppStore();
const userStore = useUserStore();
const settingsStore = useSettingsStore();

const showUserCard = ref(true);

function toggleSideBar() {
  appStore.toggleSideBar();
}

function handleCommand(command) {
  switch (command) {
    case "setLayout":
      setLayout();
      break;
    case "logout":
      logout();
      break;
    default:
      break;
  }
}

function logout() {
  ElMessageBox.confirm("确定注销并退出系统吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      userStore.logOut().then(() => {
        location.href = "/index";
      });
    })
    .catch(() => { });
}

const emits = defineEmits(["setLayout"]);
function setLayout() {
  emits("setLayout");
}

const noticeType = ref(""); // 消息类型 1通知 2公告
const noticeList = ref([]); // 消息/公告列表
const noticeTotal = ref(0); // 通知消息条数
const announcementTotal = ref(0); // 公告消息条数

// 每次展示悬浮卡片都显示通知数据
function getAllTotal() {
  noticeType.value = 1;
  handleChangeNoticeType(1);
}

// 切换通知/公告时请求数据
function handleChangeNoticeType(val) {
  noticeType.value = val;
  listNotice({
    pageNum: 1,
    pageSize: 999999,
    noticeType: val,
  }).then((res) => {
    noticeList.value = res.rows;
  });
}

// 获取公告和通知的数据
function getMessageData() {
  listNotice({
    pageNum: 1,
    pageSize: 999999,
    noticeType: 1,
  }).then((res) => {
    noticeList.value = res.rows;
    noticeTotal.value = res.total || 0;
  });
  listNotice({
    pageNum: 1,
    pageSize: 999999,
    noticeType: 2,
  }).then((res) => {
    announcementTotal.value = res.total || 0;
  });
}

// 查看更多
function handleLookMore() {
  router.push({
    path: "/system/notice",
  });
}

onBeforeMount(() => {
  getMessageData();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.module.scss";

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 56px;

  background: #ffffff;
  //box-shadow: 0px 4px 10px 3px rgba(77, 83, 131, 0.1);
  border-bottom: 1px solid #edeef3;
  z-index: 10;

  .hamburger-container {
    line-height: 56px;
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    border-right: 1px solid #d4ddea;
    background-color: #ffffff !important;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 56px;
    display: flex;

    .el-dropdown {
      display: flex !important;
      align-items: center;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 16px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      .el-dropdown {
        display: flex !important;
        align-items: center;
      }

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .line {
      height: 20px;
      border: none;
      border-right: 1px solid #d4ddea;
    }

    .avatar-container {
      margin-right: 8px;
      display: flex;
      align-items: center;

      .qrCode {
        margin-right: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .svg-icon {
          font-size: 24px;
        }
      }

      .avatar-wrapper {
        // margin-top: 5px;
        position: relative;
        border-radius: 50%;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        i {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.qrCodeBox {
  display: flex;
  padding: 16px;
  justify-content: space-around;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .el-link {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      margin-top: 6px;
      color: #2a2d36;
      border-bottom: 1px solid transparent;
      box-sizing: border-box;

      &:hover {
        border-bottom: 1px solid #2a2d36;
        //translate: borderBottom 0.5s ease;
      }
    }

    span {
      color: #2a2d36;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      margin-top: 6px;
    }
  }
}

.messageCard {
  width: 400px;

  .topLine {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eaeef5;

    button {
      padding: 0 16px;
      font-size: 14px;
      color: #2a2d36;
    }

    .active {
      color: #2468f2 !important;
    }
  }

  .middleLine {
    height: 246px;
    position: relative;

    // overflow-y: auto;
    .middleItem {
      display: flex;
      align-items: center;
      height: 76px;
      padding: 13px 20px;

      &:hover {
        background: #f7f9ff;
      }

      font-size: 14px;

      .title {
        color: $main-label-black-color;
        margin-bottom: 5px;
      }

      .time {
        color: $main-label-gray-color;
      }

      .svg-icon {
        font-size: 34px;
        flex: none;
        margin-right: 16px;
      }
    }
  }

  .bottomLine {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #eaeef5;
    padding: 12px 20px;

    span {
      height: 100%;
      border: none;
      border-right: 1px solid #e5e6eb;
    }

    button {
      flex: 1;
    }

    button:first-child {
      border-right: 1px solid #e5e6eb;
    }
  }
}

:deep(.el-popper.is-light) {
  padding: 0 !important;
}

.userPopover {
  padding: 0 !important;
}

.userCardWrapper {
  height: 320px;
  width: 208px;
  overflow: hidden;
  border-radius: 8px;
}

.userCard {
  top: 0;
  left: 0;
  position: absolute;
  height: 330px;
  width: 230px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  .top {
    width: 230px;
    height: 170px;
    border-bottom: 1px solid #eaeef5;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .middle {
    height: 112px;
    border-bottom: 1px solid #eaeef5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .el-button {
      width: 200px;
      height: 36px;
      border: 1px solid #d6deea;
      border-radius: 18px;
      color: #2a2d36;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }

    .el-button:hover {
      background: #eaf1ff;
      color: #2468f2;
      border: 1px solid #2468f2;
    }

    .el-button:active {
      background: #2468f2;
      color: #ffffff;
      border: 1px solid #2468f2;
    }

    .el-button:first-child {
      margin-bottom: 8px;
    }
  }

  .bottom {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .logout {
      font-weight: 500;
      font-size: 14px;
      color: #2a2d36;
    }

    .logout:hover {
      color: #ff6961;
    }
  }

  .user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: 30px;
    border: 1px solid #ffffff;
    filter: drop-shadow(0px 2px 3px rgba(21, 32, 101, 0.15));
  }

  span {
    margin-top: 8px;
  }

  .userName {
    font-weight: 600;
    font-size: 16px;
    color: #2a2d36;
  }

  .email {
    font-weight: 400;
    font-size: 12px;
    color: #8e8e93;
  }
}
</style>

<style lang="scss">
.el-popper.is-light {
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
}

.popperRadio {
  border-radius: 8px !important;
  padding: 0px !important;
}
</style>
