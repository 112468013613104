/**
 * 获取url中携带的参数
 * @param url
 * @returns {{}}
 */
export function getUrlParams(url) {
    let params = {}
    if (url && url.indexOf('?') > -1) {
        const paramsUrl = url.split('?')[1];
        const keyValueStr = paramsUrl.split('&');

        keyValueStr && keyValueStr.forEach(item => {
            const key = item.split('=')[0];
            const value = item.split('=')[1];
            params[key] = value
        })
    }
    return params;
}
