// tableCellColor
const textGreen = '#1CB643';
const textRed = '#f45e6c';
const textBlue = '#409CFF';
const textPurple = '#7181B7';
const textPurpleDeep = '#3A31C4';
const textOrange = '#CD6D0D';
const textGray = '#4E5969';

const bgGreen = '#EAF6EF';
const bgRed = '#FFF1E2';
const bgBlue = '#EEF8FF';
const bgPurple = '#EEF4FF';
const bgPurpleDeep = '#F1F5FF';
const bgOrange = '#FBF4E5';
const bgGray = '#F2F3F5';

// 等级
export const Level = {
    l1: {
        textColor: textPurple,
        bgColor: bgPurple,
        showPoint: false,
      },
      l2: {
        textColor: textOrange,
        bgColor: bgOrange,
        showPoint: false,
      },
      l3: {
        textColor: textPurpleDeep,
        bgColor: bgPurpleDeep,
        showPoint: false,
      },
}

// 开关类
export const OffOn = {
    off: {
        textColor: textGray,
        bgColor: bgGray,
        showPoint: true,
    },
    on: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: true,
    }
}

// 状态类
export const Status = {
    s1: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: false,
    },
    s1p: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: true,
    },
    s2: {
        textColor: textRed,
        bgColor: bgRed,
        showPoint: false,
    },
    s2p: {
        textColor: textRed,
        bgColor: bgRed,
        showPoint: true,
    },
    s3: {
        textColor: textOrange,
        bgColor: bgOrange,
        showPoint: false,
    },
    s3p: {
        textColor: textOrange,
        bgColor: bgOrange,
        showPoint: true,
    },
    s4: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: false,
    },
    s4p: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: true,
    },
    s5: {
        textColor: textGray,
        bgColor: bgGray,
        showPoint: false,
    },
    s5p: {
        textColor: textGray,
        bgColor: bgGray,
        showPoint: true,
    },


}

export const actionStatus = {
    a1: {
        textColor: textGreen,
        bgColor: bgGreen,
        showPoint: false,
    },
    a2: {
        textColor: textRed,
        bgColor: bgRed,
        showPoint: false,
    },
    a3: {
        textColor: textBlue,
        bgColor: bgBlue,
        showPoint: false,
    },
    a4: {
        textColor: textOrange,
        bgColor: bgOrange,
        showPoint: false,
    },
    a5: {
        textColor: textGray,
        bgColor: bgGray,
        showPoint: false,
    },

}