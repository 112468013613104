import { createApp } from "vue";

import Cookies from "js-cookie";
import DataVVue3 from "@kjgl77/datav-vue3";
import ElementPlus from "element-plus";
import { ElTag, ElMessage, ElMessageBox } from "element-plus";
import mitt from "mitt";
import locale from "element-plus/lib/locale/lang/zh-cn"; // 中文语言
import "@/assets/styles/index.scss"; // global css
import App from "./App";
import store from "./store";
import router from "./router";
import directive from "./directive"; // directive
// 注册指令
import plugins from "./plugins"; // plugins
import { download } from "@/utils/request";

// svg图标
import "virtual:svg-icons-register";
import SvgIcon from "@/components/SvgIcon";
import elementIcons from "@/components/SvgIcon/svgicon";


// Vue.prototype.$emitter = emitter
// Vue.prototype.$mqttClient = mqttClient

import "./permission"; // permission control
// import "uno.css";
import { useDict } from "@/utils/dict";
import {
  parseTime,
  resetForm,
  addDateRange,
  handleTree,
  selectDictLabel,
  selectDictLabels,
} from "@/utils/ruoyi";

// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
// 富文本组件
import Editor from "@/components/Editor";
// 文件上传组件
import FileUpload from "@/components/FileUpload";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
// 图片预览组件
import ImagePreview from "@/components/ImagePreview";

// 字典标签组件
import DictTag from "@/components/DictTag";
// 表格内带有颜色的状态cell组件
import StateCell from "@/components/StateCell";
//...全局内引用
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

const app = createApp(App);
// 全局方法挂载
app.config.globalProperties.useDict = useDict;
app.config.globalProperties.download = download;
app.config.globalProperties.parseTime = parseTime;
app.config.globalProperties.resetForm = resetForm;
app.config.globalProperties.handleTree = handleTree;
app.config.globalProperties.addDateRange = addDateRange;
app.config.globalProperties.selectDictLabel = selectDictLabel;
app.config.globalProperties.selectDictLabels = selectDictLabels;
app.config.globalProperties.$mitt = mitt();
// 全局组件挂载
app.component("DictTag", DictTag);
app.component("FileUpload", FileUpload);
app.component("ImageUpload", ImageUpload);
app.component("ImagePreview", ImagePreview);
app.component("RightToolbar", RightToolbar);
app.component("Editor", Editor);
app.component("StateCell", StateCell);

app.component("ElMessage", ElMessage);
app.component("ElMessageBox", ElMessage);
app.component("ElTag", ElTag);

app.use(Vue3VideoPlayer, {
  lang: "zh-CN",
});
app.use(router);
app.use(store);
app.use(plugins);
app.use(elementIcons);
app.component("svg-icon", SvgIcon);
app.use(DataVVue3);
directive(app);

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: Cookies.get("size") || "default",
});

app.mount("#app");
