export default {

    mounted(el, binding) {
        document.body.addEventListener('click', e => {
            el.contains(e.target) || binding.value();
        })
    },
    unmounted() {

        // document.removeEventListener('click')
        // document.addEventListener('click', () => {
        // })
    }
}
