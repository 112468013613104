<template>
  <div id="tags-view-container" class="tags-view-container">
    <el-button
      class="arrowBtn leftBtn"
      :class="showArrowBtn ? 'arrowBtnShow' : ''"
      circle
      icon="ArrowLeftBold"
      @mouseup="scrollToDirection('left')"
      @mousedown="stayPush('left')"
    ></el-button>
    <!-- <scroll-pane ref="scrollPaneRef" class="tags-view-wrapper" @scroll="handleScroll"> -->
    <div
      id="scrollBox"
      class="tags-view-wrapper"
      :class="showArrowBtn ? 'margin8' : ''"
    >
      <router-link
        v-for="tag in visitedViews"
        :key="tag.path"
        :data-path="tag.path"
        :id="isActive(tag) ? 'active' : ''"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        class="tags-view-item"
        :style="activeStyle(tag)"
        @click.middle="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        @contextmenu.prevent="openMenu(tag, $event)"
      >
        {{ tag.title }}
        <!-- <span class="closeIcon" v-if="!isAffix(tag)" @click.prevent.stop="closeSelectedTag(tag)"> -->
        <span class="closeIcon" @click.prevent.stop="closeSelectedTag(tag)">
          <el-icon size="14"> <close-bold /></el-icon>
          <!-- <close class="el-icon-close" style="width: 1em; height: 1em;vertical-align: middle;" /> -->
        </span>
      </router-link>
    </div>

    <!-- </scroll-pane> -->
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click="refreshSelectedTag(selectedTag)">
        <refresh-right style="width: 1em; height: 1em" /> 刷新页面
      </li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
        <close style="width: 1em; height: 1em" /> 关闭当前
      </li>
      <li @click="closeOthersTags">
        <circle-close style="width: 1em; height: 1em" /> 关闭其他
      </li>
      <li v-if="!isFirstView()" @click="closeLeftTags">
        <back style="width: 1em; height: 1em" /> 关闭左侧
      </li>
      <li v-if="!isLastView()" @click="closeRightTags">
        <right style="width: 1em; height: 1em" /> 关闭右侧
      </li>
      <li @click="closeAllTags(selectedTag)">
        <circle-close style="width: 1em; height: 1em" /> 全部关闭
      </li>
    </ul>
    <el-button
      class="arrowBtn rightBtn"
      :class="showArrowBtn ? 'arrowBtnShow' : ''"
      circle
      icon="ArrowRightBold"
      @mousedown="stayPush('right')"
      @mouseup="scrollToDirection('right')"
    ></el-button>
  </div>
</template>

<script setup>
import ScrollPane from "./ScrollPane";
import { getNormalPath } from "@/utils/ruoyi";
import useTagsViewStore from "@/store/modules/tagsView";
import useSettingsStore from "@/store/modules/settings";
import usePermissionStore from "@/store/modules/permission";
import { computed, nextTick } from "vue";
// import { useTabScroll } from '@/hooks/useTabScroll.js';

// const {
//   showArrowBtn,
//   scrollToLeft,
//   scrollToRight
// } = useTabScroll(document.getElementById('scrollBox'), document.getElementById('active'))

const visible = ref(false);
const top = ref(0);
const left = ref(0);
const selectedTag = ref({});
const affixTags = ref([]);
const scrollPaneRef = ref(null);

const { proxy } = getCurrentInstance();
const route = useRoute();
const router = useRouter();

const visitedViews = computed(() => useTagsViewStore().visitedViews);
const routes = computed(() => usePermissionStore().routes);
const theme = computed(() => useSettingsStore().theme);
const moveDistance = 200;

const showArrowBtn = ref(false);
const stayTime = ref(null);
const moveInterval = ref(null);
const isStay = ref(false);

watch(route, () => {
  addTags();
  moveToCurrentTag();
  checkSize();
  scrollToActive();
});
watch(visible, (value) => {
  if (value) {
    document.body.addEventListener("click", closeMenu);
  } else {
    document.body.removeEventListener("click", closeMenu);
  }
  checkSize();
});
onMounted(() => {
  initTags();
  addTags();
  checkSize();
});

// function scrollToLeft() {
//   nextTick(() => {
//     const scrollLeft = document.getElementById('scrollBox').scrollLeft;
//     document.getElementById('scrollBox').scrollLeft = scrollLeft - moveDistance;
//   })
// }

function stayPush(direction) {
  clearTimeout(stayTime.value);
  clearInterval(moveInterval.value);
  stayTime.value = setTimeout(() => {
    isStay.value = true;
    moveInterval.value = setInterval(() => {
      const scrollLeft = document.getElementById("scrollBox").scrollLeft;
      if (direction === "left") {
        document.getElementById("scrollBox").scrollLeft = scrollLeft - 10;
      } else {
        document.getElementById("scrollBox").scrollLeft = scrollLeft + 10;
      }
    }, 50);
  }, 1000);
}

function scrollToDirection(direction) {
  clearTimeout(stayTime.value);
  clearInterval(moveInterval.value);
  nextTick(() => {
    if (!isStay.value) {
      const scrollLeft = document.getElementById("scrollBox").scrollLeft;
      if (direction === "left") {
        document.getElementById("scrollBox").scrollLeft =
          scrollLeft - moveDistance;
      } else {
        document.getElementById("scrollBox").scrollLeft =
          scrollLeft + moveDistance;
      }
    } else {
      isStay.value = false;
    }
  });
}

// 滚动到选中的元素位置
function scrollToActive() {
  // 获取选中元素位置
  nextTick(() => {
    const offsetLeft = document.getElementById("active").offsetLeft;
    // 判断当前元素是否在视口内
    document.getElementById("scrollBox").scrollLeft = offsetLeft - 50;
  });
}

function checkSize() {
  nextTick(() => {
    const scrollWidth = document.getElementById("scrollBox").scrollWidth;
    const clientWidth = document.getElementById("scrollBox").clientWidth;
    showArrowBtn.value = scrollWidth > clientWidth;
  });
}

function isActive(r) {
  return r.path === route.path;
}
function activeStyle(tag) {
  if (!isActive(tag)) return {};
  return {
    "background-color": theme.value,
    "border-color": theme.value,
  };
}
function isAffix(tag) {
  return tag.meta && tag.meta.affix;
}
function isFirstView() {
  try {
    return (
      selectedTag.value.fullPath === "/index" ||
      selectedTag.value.fullPath === visitedViews.value[1].fullPath
    );
  } catch (err) {
    return false;
  }
}
function isLastView() {
  try {
    return (
      selectedTag.value.fullPath ===
      visitedViews.value[visitedViews.value.length - 1].fullPath
    );
  } catch (err) {
    return false;
  }
}
function filterAffixTags(routes, basePath = "") {
  let tags = [];
  routes.forEach((route) => {
    if (route.meta && route.meta.affix) {
      const tagPath = getNormalPath(basePath + "/" + route.path);
      tags.push({
        fullPath: tagPath,
        path: tagPath,
        name: route.name,
        meta: { ...route.meta },
      });
    }
    if (route.children) {
      const tempTags = filterAffixTags(route.children, route.path);
      if (tempTags.length >= 1) {
        tags = [...tags, ...tempTags];
      }
    }
  });
  return tags;
}
function initTags() {
  const res = filterAffixTags(routes.value);
  affixTags.value = res;
  for (const tag of res) {
    // Must have tag name
    if (tag.name) {
      useTagsViewStore().addVisitedView(tag);
    }
  }
}
function addTags() {
  const { name } = route;
  if (name) {
    useTagsViewStore().addView(route);
    if (route.meta.link) {
      useTagsViewStore().addIframeView(route);
    }
  }
  return false;
}
function moveToCurrentTag() {
  nextTick(() => {
    for (const r of visitedViews.value) {
      if (r.path === route.path) {
        scrollPaneRef.value?.moveToTarget(r);
        // when query is different then update
        if (r.fullPath !== route.fullPath) {
          useTagsViewStore().updateVisitedView(route);
        }
      }
    }
  });
}
function refreshSelectedTag(view) {
  proxy.$tab.refreshPage(view);
  if (route.meta.link) {
    useTagsViewStore().delIframeView(route);
  }
}
function closeSelectedTag(view) {
  proxy.$tab.closePage(view).then(({ visitedViews }) => {
    if (isActive(view)) {
      toLastView(visitedViews, view);
    }
  });
  checkSize();
}
function closeRightTags() {
  proxy.$tab.closeRightPage(selectedTag.value).then((visitedViews) => {
    if (!visitedViews.find((i) => i.fullPath === route.fullPath)) {
      toLastView(visitedViews);
    }
  });
}
function closeLeftTags() {
  proxy.$tab.closeLeftPage(selectedTag.value).then((visitedViews) => {
    if (!visitedViews.find((i) => i.fullPath === route.fullPath)) {
      toLastView(visitedViews);
    }
  });
}
function closeOthersTags() {
  router.push(selectedTag.value).catch(() => {});
  proxy.$tab.closeOtherPage(selectedTag.value).then(() => {
    moveToCurrentTag();
  });
}
function closeAllTags(view) {
  proxy.$tab.closeAllPage().then(({ visitedViews }) => {
    if (affixTags.value.some((tag) => tag.path === route.path)) {
      return;
    }
    toLastView(visitedViews, view);
  });
}
function toLastView(visitedViews, view) {
  const latestView = visitedViews.slice(-1)[0];
  if (latestView) {
    router.push(latestView.fullPath);
  } else {
    // now the default is to redirect to the home page if there is no tags-view,
    // you can adjust it according to your needs.
    if (view.name === "Dashboard") {
      // to reload home page
      router.replace({ path: "/redirect" + view.fullPath });
    } else {
      router.push("/");
    }
  }
}
function openMenu(tag, e) {
  const menuMinWidth = 105;
  const offsetLeft = proxy.$el.getBoundingClientRect().left; // container margin left
  const offsetWidth = proxy.$el.offsetWidth; // container width
  const maxLeft = offsetWidth - menuMinWidth; // left boundary
  const l = e.clientX - offsetLeft + 15; // 15: margin right

  if (l > maxLeft) {
    left.value = maxLeft;
  } else {
    left.value = l;
  }

  top.value = e.clientY - 36;
  visible.value = true;
  selectedTag.value = tag;
}
function closeMenu() {
  visible.value = false;
}
function handleScroll() {
  closeMenu();
}
</script>

<style lang="scss" scoped>
.tags-view-container {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  height: 48px;
  width: 100%;
  background: #f2f3f5;
  position: relative;
  //background-color: #2e62cb;
  // border-bottom: 1px solid #d8dce5;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  .tags-view-wrapper {
    height: 100%;
    .tags-view-item {
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      height: 100%;
      line-height: 32px;
      // border: 1px solid #e3e4eb;
      // border-radius: 4px;
      height: 100%;
      color: #1D2129;
      background: transparent;
      padding: 0 12px;
      font-size: 14px;
      // margin-left: 5px;
      // margin-top: 4px;
      font-weight: 400;

      .closeIcon {
        width: 0;
        overflow: hidden;
        opacity: 0;
        transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }

      &:hover {
        background: #4d7bda;
        .closeIcon {
          width: 12px;
          opacity: 1;
          transform: rotate(360deg);
          transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out, rotate;
        }
      }

      &:first-of-type {
        // margin-left: 15px;
      }

      &:last-of-type {
        // margin-right: 15px;
      }

      &.active {
        background-color: #4080FF !important;
        color: #fff;
        font-weight: bold;

        // &::before {
        //   content: "";
        //   background: #fff;
        //   display: inline-block;
        //   width: 8px;
        //   height: 8px;
        //   border-radius: 50%;
        //   position: relative;
        //   margin-right: 2px;
        // }
      }

      .closeIcon {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .tags-view-item:hover {
      background: #ecf2ff;
      color: #4080ff;
      .closeIcon {
        color: #4080ff;
      }
    }

    .active:hover {
      background: #2955ae;
      color: #ffffff;
      .closeIcon {
        color: #ffffff;
      }
    }
  }

  .margin8 {
    margin: 0 8px;
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}

.arrowBtn {
  height: 20px;
  width: 20px;
  color: #409cff!important;
  border: 1px solid #FFFFFF!important;
  background: #eef8ff;
  padding: 0;
  // position: absolute;
  z-index: 10;
  overflow: hidden;
  width: 0;
  opacity: 0;
  .el-icon {
    color:#409bfe;
  }
  //transition:  translate width 0.3s ease, opacity 0.3s ease;
}

.arrowBtn:hover {
  color: #409cff!important;
  border: 1px solid #d3e7ff!important;
  background-color: #eef8ff!important;
}

.arrowBtn:active {
  color: #2468f2!important;
  border: 1px solid #2468f2 !important;
  background-color: #eaf1ff!important;
}

.leftBtn {
  left: 8px;
}

.rightBtn {
  right: 8px;
}

.arrowBtnShow {
  width: 20px;
  opacity: 1;
  //translate: width 0.3s ease, opacity 0.3s ease;
}

#scrollBox {
  scroll-behavior: smooth;
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      color: #c3c7ce; // &:before {
      //   transform: scale(0.6);
      //   display: inline-block;
      //   vertical-align: -3px;
      // }

      &:hover {
        background-color: #b4bccc;
        color: #fff;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}
</style>
